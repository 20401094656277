<template>
	<v-sheet class="asset" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="2" class="py-0 my-auto">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" :text-color="item.textcolor">
								{{ item.asset_status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="10" class="py-1 my-auto text-right listing-right">
				<v-layout class="justify-end">
					<div class="mx-2" style="max-width: 300px">
						<date-range-picker
							v-on:click:clear="
								s_search['date-range'] = [];
								searchOrders();
							"
							hide-details
							hide-top-margin
							clearable
							v-model="s_search['date-range']"
						></date-range-picker>
					</div>

					<div class="d-flex">
						<v-btn
							color="blue darken-4 text-white"
							class="mx-2"
							depressed
							tile
							:disabled="pageLoading"
							v-on:click="searchOrders()"
							><v-icon center> mdi-magnify </v-icon></v-btn
						>
						<v-btn
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							tile
							v-on:click="advanceSearch = true"
						>
							<v-icon>mdi-filter</v-icon>
						</v-btn>

						<v-menu
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-edit</v-icon>
								</v-btn>
							</template>
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('group')"
							>
								<template v-for="cols in draggableThead">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('group')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</v-menu>
					</div>
				</v-layout>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="12">
				<v-layout class="page-summary show">
					<v-flex class="summary-details bg-light-red" md4>
						<v-icon color="blue">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">All Assets</div>
						<div class="number red--text">{{ assetCount.allAsset }}</div>
					</v-flex>
					<v-flex class="summary-details bg-light-red" md4>
						<v-icon color="green">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">Active</div>
						<div class="number red--text">{{ assetCount.activeAsset }}</div>
					</v-flex>
					<v-flex class="summary-details bg-light-red" md4>
						<v-icon color="orange">mdi-file-chart-outline</v-icon>
						<div class="name text-capitalize">In-Active</div>
						<div class="number red--text">{{ assetCount.inActiveAsset }}</div>
					</v-flex>
					<!-- <v-flex class="summary-details bg-light-red" md4>
					<v-icon color="red">mdi-file-chart-outline</v-icon>
					<div class="name text-capitalize">
						Retired 
					</div>
					<div class="number red--text">{{assetCount.retiredAsset}}</div>
				</v-flex>
				<v-flex class="summary-details bg-light-red" md4>
					<v-icon color="red">mdi-file-chart-outline</v-icon>
					<div class="name text-capitalize">
						Issues
					</div>
					<div class="number red--text">{{assetCount.issueAsset}}</div>
				</v-flex>
				<v-flex class="summary-details bg-light-red" md4>
					<v-icon color="red">mdi-file-chart-outline</v-icon>
					<div class="name text-capitalize">
						Under Service
					</div>
					<div class="number red--text">{{assetCount.underServiceAsset}}</div>
				</v-flex>
				<v-flex class="summary-details bg-light-red" md4>
					<v-icon color="red">mdi-file-chart-outline</v-icon>
					<div class="name text-capitalize">
						Warranty Expire
					</div>
					<div class="number red--text">{{assetCount.warrantyExpire}}</div>
				</v-flex>
				<v-flex class="summary-details bg-light-red" md4>
					<v-icon color="red">mdi-file-chart-outline</v-icon>
					<div class="name text-capitalize">
						Overdue
					</div>
					<div class="number red--text">{{assetCount.overDue}}</div>
				</v-flex> -->
				</v-layout>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Asset #, Name, Company Name, Display Name and Email Address"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<!-- <Table
			:page-loading="pageLoading"
			type="asset-warranty"
			delete-endpoint="asset-warranty/"
			detail-route="detail-warranty"
			:dialog-update="true"
			
			v-on:update:dialog="updateDialog"
			delete-note="All transactions of this asset will also be deleted."
		></Table> -->
		<Table
			:page-loading="pageLoading"
			type="asset-warranty"
			delete-endpoint="asset-warranty/"
			detail-route="detail-warranty"
			update-route="asset-update"
			delete-note="All transactions of this asset will also be deleted."
		></Table>
		<ExportDialog
			endpoint="asset/export?type=asset&current=1"
			title="Assets"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="asset/export?type=asset"
			title="Assets"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<warrantyDialog
			endpoint="asset-warranty/"
			title="Assets"
			:export-dialog.sync="warrantyDialog"
			v-on:close="warrantyDialog = false"
		></warrantyDialog>
		<ImportDialog
			endpoint="asset/import?type=asset"
			title="Assets"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Assets"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import ApiService from "@/core/services/api.service";
import ObjectPath from "object-path";
import { isString } from "lodash";
import DateRangePicker from "@/view/components/DateRangePicker";
import warrantyDialog from "@/view/module/Warranty/warranty-update";

export default {
	name: "asset-warranty-listing",
	title: "Listing Asset",
	mixins: [ListingMixin],
	data() {
		return {
			pageTitle: "Warranty Assets",
			pageBreadcrumbs: [{ text: "Asset", disabled: true }],
			endpoint: "asset-warranty",
			defaultFilter: {},
			warrantyDialog: false,
			assetCount: [],
			s_search: {
				"date-range": [],
			},
		};
	},

	methods: {
		updateDialog() {
			this.warrantyDialog = false;
		},
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.s_search,
					},
				})
				.then(() => {
					this.getListing();
				})
				.catch(() => {});
		},
		getAssetCount() {
			ApiService.setHeader();
			ApiService.get(`asset-warranty-count`)
				.then(({ data }) => {
					this.assetCount = data;
				})
				.catch(() => {});
		},
	},
	mounted() {
		const date_range = ObjectPath.get(this.$route, "query.date-range", []);
		if (isString(date_range)) {
			this.s_search["date-range"] = [date_range];
		} else {
			this.s_search["date-range"] = date_range;
		}
		this.getAssetCount();
	},
	components: {
		DateRangePicker,
		warrantyDialog,
	},
};
</script>
