<template>
	<Dialog :dialog.sync="exportDialog" :dialog-width="864">
		<template v-slot:title> Edit {{ title }} </template>
		<template v-slot:body>
			<v-form
				ref="exportForm"
				v-model.trim="export_valid"
				lazy-validation
				v-on:submit.stop.prevent="exportFilter"
			>
				<v-row class="px-4">
					<v-col md="3" class="my-auto py-0">
						<label for="cost-price" class="btx-label mt-2 required">Start Date</label>
					</v-col>
					<v-col md="9" class="py-0">
						<DatePicker
							hide-details
							clearable
							:disabled="pageLoading"
							:loading="pageLoading"
							id="warranty-start-date"
							placeholder="Start Date"
							v-model="asset.warranty_start_date"
							:rules="[vrules.required(asset.warranty_start_date, 'Warranty Start Date')]"
							:class="{
								required: !asset.warranty_start_date,
							}"
						></DatePicker>
					</v-col>
					<v-col md="3" class="my-auto py-0">
						<label for="vendor" class="btx-label mt-2 required">End Date</label>
					</v-col>
					<v-col md="9" class="py-0">
						<DatePicker
							hide-details
							clearable
							:disabled="pageLoading"
							:loading="pageLoading"
							id="warranty-end-date"
							placeholder="End Date"
							:min-date="asset.warranty_start_date"
							v-model="asset.warranty_end_date"
							:rules="[vrules.required(asset.warranty_end_date, 'Warranty End Date')]"
							:class="{
								required: !asset.warranty_end_date,
							}"
						></DatePicker>
					</v-col>
					<v-col md="3" class="my-auto py-0">
						<label for="location" class="btx-label mt-2 required">Reminder Date</label>
					</v-col>
					<v-col md="9" class="py-0">
						<DatePicker
							hide-details
							clearable
							:disabled="pageLoading"
							:loading="pageLoading"
							id="warranty-reminder-date"
							placeholder="Reminder Date"
							v-model="asset.warranty_reminder_date"
							:min-date="asset.warranty_start_date"
							:max-date="asset.warranty_end_date"
							:rules="[vrules.required(asset.warranty_reminder_date, 'Warranty Reminder Date')]"
							:class="{
								required: !asset.warranty_reminder_date,
							}"
						></DatePicker>
					</v-col>
					<v-col md="3" class="my-auto py-0">
						<label for="attachment" class="btx-label mt-2">Attachment</label>
					</v-col>
					<v-col md="9" class="py-0">
						<template v-for="(data, index) in wtx_attachments">
							<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
								<v-col md="5" class="py-0">
									<v-file-input
										v-model="data.file"
										placeholder="Click here to select files"
										color="blue darken-4"
										multiple
										outlined
										class="mt-3"
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-on:change="limitAttachmentWarranty($event, index)"
									>
									</v-file-input>
								</v-col>
								<v-col md="5" class="py-0">
									<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
								</v-col>
								<v-col md="1" class="py-0">
									<v-btn
										:disabled="wtx_attachments.length < 2"
										v-on:click="removeFileWarranty(index)"
										class="white--text mt-3"
										depressed
										color="red darken-4"
										tile
										style="min-width: 18px !important; margin-left: -16px"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-col>
								<v-col md="1" class="py-0">
									<v-btn
										:disabled="wtx_attachments.length > 2"
										v-on:click="addMoreWarranty()"
										class="white--text mt-3"
										depressed
										color="blue darken-4"
										tile
										style="min-width: 18px !important; margin-left: -16px"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</template>
					</v-col>
					<v-col md="12" class="min-height-40"> </v-col>
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn class="white--text" depressed color="blue darken-4" tile v-on:click="exportFilter">
				save
			</v-btn>
			<v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<style>
.warranty {
	max-width: 864px;
}
</style>
<script>
import { mapGetters } from "vuex";
import { GetAssetWarranty } from "@/core/lib/asset.lib";
import DatePicker from "@/view/components/DatePicker.vue";
import TextInput from "@/view/components/TextInput.vue";
import Dialog from "@/view/components/Dialog.vue";
import { UploadFiles } from "@/core/lib/upload.lib";
import ObjectPath from "object-path";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_ERROR } from "@/core/services/store/common.module";
import { map } from "lodash";

export default {
	name: "export-dialog",
	data() {
		return {
			asset: {
				warranty_start_date: null,
				warranty_end_date: null,
				warranty_reminder_date: null,
			},
			uuid: null,
			export_valid: true,
			pageLoading: false,
			wtx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
		};
	},
	props: {
		exportDialog: {
			type: Boolean,
			default: false,
		},
		current: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
	},
	methods: {
		getAsset() {
			GetAssetWarranty(this.uuid)
				.then((data) => {
					console.log(data);
					/* this.barcode = data.barcode;
					this.asset = data;
					this.assetId = data.id;
					this.deleteText = `#${data.barcode} - ${data.name}`;
					this.deleteURL = `asset-warranty/${data.uuid}`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Warranty", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]); */
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		addMoreWarranty() {
			this.wtx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFileWarranty(index) {
			this.wtx_attachments.splice(index, 1);
			if (this.wtx_attachments.length < 1) {
				this.addMoreWarranty();
			}
		},
		limitAttachmentWarranty(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.wtx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.wtx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		exportFilter() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.exportForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.exportForm.validate()) {
				return false;
			}

			let filter = {
				startDate: _this.export_from_date || null,
				endDate: _this.export_to_date || null,
				extensions: _this.export_as,
				search: _this.search,
			};

			_this.exportAsset(filter);

			_this.$emit("close", true);
		},
		exportAsset(data) {
			ApiService.setHeader();
			let downloadURL = process.env.VUE_APP_API_URL + this.endpoint;
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			const { query } = this.$route;

			if (data.startDate != null && data.endDate != null) {
				downloadURL.searchParams.append("start_date", data.startDate);
				downloadURL.searchParams.append("end_date", data.endDate);
			}
			if (data.extensions) {
				downloadURL.searchParams.append("extensions", data.extensions);
			}
			/* if (this.listingSearch) {
				downloadURL.searchParams.append("search", this.listingSearch);
			} */
			if (query) {
				map(query, (row, index) => {
					if (row) {
						downloadURL.searchParams.append(`${index}`, row);
					}
				});
			}
			downloadURL.searchParams.append("token", token);
			window.open(downloadURL, "_blank");
		},
	},
	components: {
		DatePicker,
		TextInput,
		Dialog,
	},
	mounted() {
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		//this.getAsset();
	},

	computed: {
		...mapGetters(["listingSearch", "errors"]),
		exportButton() {
			return this.current ? "Export Current View" : "Export";
		},
	},
};
</script>
